#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 2rem;
}

#nextCity {
  font-weight: bold;
}

.cityMarker i {
  font-size: 30px;
  color: red;
}

.playerMarker i {
  font-size: 26px;
}

.content-box {
  /* border: 1px solid #888888; */
  background-color: #efefef;
}

.progress {
  border-radius: 0;
}

.footer {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 10px;
}

#home {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #d6d6d6;
  cursor: pointer;
}

#home:hover {
  color: darkgrey;
}

#home:active {
  color: rgb(139, 139, 139);
}

/* #screenLobby {
  display: none;
}

#screenMap {
  display: none;
} */

#mapOverlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.7);
}

#mapOverlayCountdown {
  font-size: 5rem;
}

#selfInList {
  font-weight: bold;
  cursor: pointer;
}

#settingsSaved {
  display: none;
  font-size: smaller;
  color: green;
}

.error-msg {
  color: firebrick;
  display: none;
  position: absolute;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.points {
  color: green;
  animation-name: fadeOutUp;
  animation-duration: 3s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  position: fixed;
  left: -35px;
  text-align: center;
  top: -15px;
  width: 100px;
  font-size: 1rem;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.distanceMarker {
  width: 80px;
  text-align: center;
  transform-origin: center;
  position: absolute;
  left: -40px;
  top: -0.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  z-index: 20;
}

#ingameScoreList {
  padding-inline-start: 20px;
}

.rotate {
  /* animation-name: fadeOutUp; */
  animation-name: rotate;
  animation-duration: 2s;
  /* animation-fill-mode: both; */
  animation-iteration-count: 1;
}
